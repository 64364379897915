@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@font-face {
  font-family: 'Colus';
  src: url('Colus-Regular.eot');
  src: url('Colus-Regular.eot?#iefix') format('embedded-opentype'),
    url('Colus-Regular.woff2') format('woff2'),
    url('Colus-Regular.woff') format('woff'),
    url('Colus-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Georgia';
  src: url('Georgia-Bold-Italic.ttf');
  src: url('Georgia-Bold-Italic.ttf?#iefix') format('embedded-opentype'),
    url('Georgia-Bold-Italic.ttf') format('ttf'),
    url('Georgia-Bold-Italic.ttf') format('ttf'),
    url('Georgia-Bold-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Candara';
  src: url('Candara.eot');
  src: url('Candara.eot?#iefix') format('embedded-opentype'),
    url('Candara.woff2') format('woff2'), url('Candara.woff') format('woff'),
    url('Candara.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Matricha';
  src: url('Matricha.ttf');
  src: url('Matricha.ttf?#iefix') format('embedded-opentype'),
    url('Matricha.ttf') format('ttf'), url('Matricha.ttf') format('ttf'),
    url('Matricha.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .safariinput label {
      translate: transform(14px, 12px);
    }
  }
}

/* @media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .safariinput label {
      translate: transform(14px, 12px);
    }
  }
} */
